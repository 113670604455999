import { getPawIconTemplate } from './createPawIconTemplate';

/**
 * Generates HTML markup for ad rendering
 * @param {Object} ad
 * @param {Object} positionOptions
 * @returns {String}
 */
const getAdMarkup = ({ dsp, responsive, width, height, adInfoUrl }, positionOptions) => {
  const size = (dim) => (responsive ? '100%' : `${dim}px`);
  const dimensions = `width: ${size(width)}; height: ${size(height)};`;

  let pawDisable = !!(positionOptions && positionOptions.pawDisable);

  // native ad will render its own 'reklama' text instead of paw icon
  if (['SKLIK', 'OGAR', 'ONEGAR'].includes(dsp) && width === 111 && height === 111 && responsive === 1) {
    pawDisable = true;
  }

  return `
		<div class="sssp-posCont" style="width:100%;height:100%;position:relative;padding:0px;margin:0px">
			<div
				class="sssp-resizeCont"
				data-cy="ad-content"
				style="${dimensions}"
			></div>
			${pawDisable ? '' : getPawIconTemplate(dsp, adInfoUrl)}
		</div>
	`;
};

/**
 * Returns an object with ready-to-use DOM Node objects
 * to insert into the page and to render an ad into.
 * @param {Object} ad
 * @param {Object} positionOptions
 * @returns {Object}
 */
export const getAdElements = (ad, positionOptions) => {
  const htmlString = getAdMarkup(ad, positionOptions);
  const range = document.createRange();
  const adElement = range.createContextualFragment(htmlString);

  return {
    adElement,
    adContent: adElement.querySelector('.sssp-resizeCont'),
  };
};
